<template>
    <LiefengContent>
        <template v-slot:title>{{ !routerObj.type || routerObj.type == '' ? `广州市${routerObj.zone}${routerObj.street}${routerObj.community}服务情况明细` : '服务明细' }}</template>
        <template v-slot:toolsbarRight>
            <div class="header-content" style="display: flex">
                <div style="margin-left: 20px" v-for="(value, key) in headersObj" :key="value">
                    <span style="margin-left: 5px">{{ key }}</span>
                    :
                    <span>{{ value }}</span>
                    次
                </div>
            </div>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" :total="total" :loading="loading" :fixTable="true" :hidePage="true"></LiefengTable>
            <!-- 导入 -->
            <LiefengModal title="服务记录详情" width="40%" height="600px" :value="modalStatus" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <div class="content-div">
                        <div class="item">
                            <div class="title">服务主题：</div>
                            <div class="other">
                                {{ detailMessage.name || "未知" }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="title">服务人数：</div>
                            <div class="other">{{ detailMessage.people || 0 }}</div>
                        </div>
                        <div class="item">
                            <div class="title">解决问题个数：</div>
                            <div class="other">{{ detailMessage.problem || 0 }}</div>
                        </div>
                        <div class="item">
                            <div class="title">打卡时间：</div>
                            <div class="other">{{ detailMessage.gmtCreate ? $core.formatDate(new Date(detailMessage.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "" }}</div>
                        </div>
                        <div class="item">
                            <div class="title">服务时间：</div>
                            <div class="other">{{ detailMessage.serviceTime || ''}}</div>
                        </div>
                        <div class="item">
                            <div class="title">研究解决的具体问题：</div>
                            <div class="desc" v-html="detailMessage.content || ''"></div>
                        </div>
                        <div class="item">
                            <div class="title">现场图：</div>
                            <div class="other">
                                <a v-for="(item, index) in detailMessage.img" target="view_window" :href="item" :key="index"><img v-if="item" :src="item" style="width: 200px; height: 200px;margin:10px" /></a>
                            </div>
                        </div>
                        <div class="item">
                            <div class="title">主要负责人：</div>
                            <div class="other">{{ detailMessage.creatorName }}</div>
                        </div>
                    </div>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="excelModalData(false)">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
    data() {
        return {
            loading: false,
            //表头列表
            talbeColumns: [
                {
                    title: "服务主题",
                    key: "name",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "现场图",
                    key: "zone",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        if (params.row.img && params.row.img.length) {
                            return params.row.img.map(item => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            textAlign: "center",
                                            padding: "10px 0",
                                            display: item && item != "" ? "inline-block" : "none",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                        },
                                    },
                                    [
                                        h(
                                            "a",
                                            {
                                                attrs: {
                                                    target: "view_window",
                                                    href: item,
                                                },
                                            },
                                            [
                                                h("img", {
                                                    attrs: {
                                                        src: item,
                                                    },
                                                    style: {
                                                        width: "40px",
                                                        height: "40px",
                                                    },
                                                }),
                                            ]
                                        ),
                                    ]
                                )
                            })
                        } else {
                            return h("div", "")
                        }
                    },
                },
                {
                    title: "服务类型",
                    key: "types",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "服务人数",
                    key: "people",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "主要负责人",
                    key: "creatorName",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "解决问题个数",
                    key: "problem",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "打卡时间",
                    key: "gmtCreate",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "服务时间",
                    key: "serviceTime",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "操作",
                    align: "center",
                    width: 120,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "primary",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.detailMessage = params.row
                                                this.excelModalData(true)
                                                console.log("this.detailMessage", this.detailMessage)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            textAlign: "center",
                                        },
                                    },
                                    "详情" //1001
                                ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
            routerObj: {},
            selectList: [],
            headersObj: {},

            detailMessage: {},

            modalStatus: false,
        }
    },
    async created() {
        let data = JSON.parse(this.$route.query.obj.replace(/\'/g, '"'))
        this.routerObj = data
        await this.getDictTye()
        await this.getList()
    },
    methods: {
        excelModalData(status) {
            this.modalStatus = status
        },
        getList() {
            this.loading = true
            this.$get("/gateway/apps/o2o/api/o2o/test15min/selectByOrgCode", {
                orgCode: this.routerObj.communityCode,
                month:this.routerObj.month || ''
            }).then(res => {
                this.loading = false
                this.tableData = []
                if (res && res.code == 200 && res.dataList) {
                    this.tableData = res.dataList
                        .map(item => {
                            if (item.config && item.config != "") {
                                return {
                                    ...item,
                                    ...JSON.parse(item.config),
                                }
                            } else {
                                return {
                                    ...item,
                                }
                            }
                        })
                        .map(item => {
                            item.types = ""
                            this.selectList.map(items => {
                                if (item.type.indexOf(items.dictKey) > -1) {
                                    item.types = `${item.types}${items.dictValue},`
                                }
                            })
                            return {
                                ...item,
                            }
                        })
                        .map(item => {
                            return {
                                ...item,
                                img: item.img && item.img != "" ? item.img.split(",") : "",
                            }
                        })

                    console.log(this.tableData)
                } else {
                    this.tableData = []
                    this.total = 0
                }
            })
        },
        async getDictTye() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                appCode: "SUIYUE",
                dictType: "O2O_SERVICE_TYPE",
            }).then(res => {
                if (res.code == 200) {
                    this.selectList = res.dataList.map(item => {
                        for (var k in this.routerObj) {
                            if (k == item.dictKey) {
                                this.headersObj[item.dictValue] = this.routerObj[k]
                            }
                        }
                        return {
                            ...item,
                        }
                    })
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'>
.content-div {
    .item {
        display: flex;
        margin: 20px 0;
        .title {
            width: 150px;
        }
        .other {
            flex: 1;
        }
    }
}
</style>